<template>
  <DistributeursListeMainVue/>
</template>

<script>
import DistributeursListeMainVue from '@/views/afridia/distributeurs/liste/DistributeursListeMainVue.vue'

export default {
  name: 'MainVue',
  components: { DistributeursListeMainVue },

  data() {
    return {
      distributeurs: [
      ],
    }
  },
}
</script>

<style scoped>

</style>
